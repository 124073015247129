import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import AddressCard from "./addressCard";

function Address({
  addresses,
  selectedAddress,
  selectAddressHandler,
  clearSelectedHandler,
  showEditHandler,
  ...otherProps
}) {

  let addressList = addresses?.map(address => {

    return (
      <Box mr={2} key={address.id}>
        <AddressCard
          address={address}
          isSelected={selectedAddress?.id === address.id}
          selectAddressHandler={selectAddressHandler}
          clearSelectHandler={() => clearSelectedHandler()}
          showEditHandler={showEditHandler}
        />
      </Box>
    );
  });

  return (
    addressList.length
      ? addressList
      : 'No address available, Please add address'
  );
}

Address.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.object),
  selectAddressHandler: PropTypes.func,
  clearSelectedHandler: PropTypes.func,
  showEditHandler: PropTypes.func,
};

export default Address;
