import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';
import useStyles from './addToCartAddons.style';
import { connect } from 'react-redux';
import { CURRENCY_SYMBOL } from '../../util/constants';

function AddToCartAddons({ item, updating, selectHandler, ...otherProps }) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  
  const handleToggle = (id) => () => {
    
    const currentIndex = checked.indexOf(id);
    const newChecked = [...checked];
    
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  
  // auto select already added addons in cart
  useEffect(() => {
    if (updating) {
      const cartAddonIds = item?.selected_addon.map(cartAddon => cartAddon.id);
    setChecked(cartAddonIds);
    }
  }, [updating]);
  
  
  // update parent about selected addons
  useEffect(() => {
    const checkedAddons = item.addons.filter(addon => checked.includes(addon.id));
    selectHandler(checkedAddons);
  }, [checked]);
  
  return (
    <List className={classes.root}>
      
      {item.addons.map(addon => {
        const labelId = `checkbox-list-label-${addon.id}`;
        
        return (
          <ListItem key={addon.id} role={undefined} dense button onClick={handleToggle(addon.id)}>
            <ListItemIcon className={classes.selectBox}>
              <Checkbox
                edge="start"
                color="primary"
                checked={checked.indexOf(addon.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <Box display="flex" justifyContent="space-between" width="100%">
              <div
                id={labelId}
                className={classes.addonName}
              >
                {addon.name}
              </div>
              <div className={classes.addonPrice}>
                 {CURRENCY_SYMBOL} {addon.price.toFixed(2)}
              </div>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
}

AddToCartAddons.propTypes = {
  item: PropTypes.object.isRequired,
  selectHandler: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  cartAddons: state.cart.addons,
});

export default connect(mapStateToProps)(AddToCartAddons);
