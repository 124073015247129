import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring'; // cjs for IE 11

const FadeAnimation = forwardRef(function Fade(
  { in: open, children, onEnter, onExited, ...otherProps },
  ref
) {
  
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });
  
  return (
    <animated.div ref={ref} style={style} {...otherProps}>
      {children}
    </animated.div>
  );
});

FadeAnimation.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default FadeAnimation;
