import { FOOTER_HIDE, FOOTER_SHOW, HEADER_HIDE, HEADER_SHOW, SHOW_AUTO_OFFER_POPUP, SHOW_LEFT_SIDE_BAR, SHOW_PROMOTIONAL_POPUP } from './layoutTypes';


export const headerShowHide = (show = true) => {
  return {
    type: show ? HEADER_SHOW : HEADER_HIDE
  };
};

export const footerShowHide = (show = true) => {
  return {
    type: show ? FOOTER_SHOW : FOOTER_HIDE
  };
};

export const updateShowPromotionalPopup = payload => {
  return {
    type: SHOW_PROMOTIONAL_POPUP,
    payload: payload,
  };
};

export const updateShowAutoOfferPopup = payload => {
  return {
    type: SHOW_AUTO_OFFER_POPUP,
    payload: payload,
  };

};

export const updateShowLeftSideBar = payload => {
  return {
    type: SHOW_LEFT_SIDE_BAR,
    payload: payload,
  }
}

