import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Box} from "@material-ui/core";

export default function PageHeaderBreadcrumb({pageName, component, padding}) {
  
  return (<>
    <section className="w3l-breadcrumb">
      <div className="container">
        {
          component &&
          <Box className="breadcrumbs-custom-path" padding={padding}>
            {component}
          </Box>
        }

        {
          !component &&
          <ul className="breadcrumbs-custom-path">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">
              <span className="fa fa-arrow-right mx-2" aria-hidden="true" />
              { pageName }
            </li>
          </ul>
        }
      </div>
    </section>
  </>);
}

PageHeaderBreadcrumb.prototypes = {
  pageName: PropTypes.string.isRequired
};
