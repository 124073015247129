import {
  SETTING_GET_REQUEST,
  SETTING_GET_FAIL,
  SETTING_GET_SUCCESS
} from './settingTypes';
import { apiRequest } from '../../util/util';
import { API_GET_SETTING, AUTH_ENDPOINT_LOGIN, AUTH_USER_ENDPOINT, PRE_AUTH_ENDPOINT } from '../../util/constants';

///// Login
export const settingRequest = () => {
  return {
    type: SETTING_GET_REQUEST
  };
};

export const settingRequestFail = err => {
  return {
    type: SETTING_GET_FAIL,
    payload: err
  };
};

export const settingRequestSuccess = settings => {
  return {
    type: SETTING_GET_SUCCESS,
    payload: settings
  };
};

export const getSetting = () => {
  return async (dispatch) => {
    
    dispatch(settingRequest());
    
    try {
      
      // get setting from api
      const settings = await apiRequest.get(API_GET_SETTING);
      
      dispatch(settingRequestSuccess(settings.data?.data));
      
    } catch (e) {
      dispatch(settingRequestFail(e.response?.data));
    }
    
  };
};
