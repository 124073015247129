import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
        paddingTop: 20,
    },
    header: {
        textAlign: 'center',
        fontSize: 25,
    },

    itemColor: {
        color: 'black',
    },

    itemTextSize: {
        fontSize: 18,
    },

    addonColor: {
        color: 'green',
    },

    addonTextSize: {
        fontSize: 14,
    },

    priceColor: {
        color: 'black',
    },

    priceTextSize: {
        fontSize: 16,
    },

    cartTitleSize: {
        fontSize: 18,
    },

    cartTitleColor: { 
        color: 'gray',
    },

    display: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    total: {
        marginTop: 20,
    },

    itemMargin: {
        marginBottom: 8,
    },
    marginBottom20:{
        marginBottom:20,
    },

    spaceBetween:{
        display: "space-between",
    },

    itemRight:{ 
        textAlign:"right",
    },

}));


