import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  selectBox: {
    minWidth: '35px'
  },
  addonName: {
    flex: 'unset',
    fontSize: '17px'
  },
  addonPrice: {
    flex: 'unset',
    fontWeight: 700,
  }
}));

export default useStyles;
