import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MobileStepper, Paper, Typography, Button, Box } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import CardPaymentAddressStep from "./cardPaymentAddressStep";
import CardPaymentCardDetailsStep from "./cardPaymentCardDetailsStep";
import useNotify from "../../hooks/useNotify";
import * as colors from '@material-ui/core/colors';
import CircularProgress from "@material-ui/core/CircularProgress";


const steps = ['Billing Address', 'Card Details'];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  nav: {
    backgroundColor: colors.grey["200"],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));



export default function CardPaymentSteps() {
  const classes = useStyles();
  const [notify] = useNotify();

  const [executePayment, setExecutePayment] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);

  // address
  const [address, setAddress] = useState({
    street: '',
    city: '',
    postcode: '',
    county: '',
  });
  const updateAddress = ({ target }) => {
    const field = target.name;
    const value = target.value;

    const newAddress = {
      ...address,
      [field]: value
    };

    setAddress(newAddress);
  };

  // steps
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;
  const isLastStep = (activeStep === maxSteps - 1);
 
  const handleNext = () => {

    // all address inputs must be filled
    const addressFields = Object.keys(address);
    const isAddressFilled = addressFields.filter(itm => address[itm].length);

    if (addressFields.length !== isAddressFilled.length) {
      notify.default('Please fill all address fields');
      return false;
    }

    // handle last step
    if (isLastStep) {
      const paymentStatus = makePayment();
      return paymentStatus;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <CardPaymentAddressStep
          address={address}
          changeHandler={updateAddress}
        />;

      case 1:
        return <CardPaymentCardDetailsStep
          address={address}
          initiatePayment={executePayment}
          stepBackHandler={handleBack}
          paymentStatusHandler={(val) => setPaymentInProgress(val)}
        />;


      default:
        throw new Error('Undefined step');
    }
  };

  const makePayment = () => {
    setExecutePayment(true);
    setTimeout(() => setExecutePayment(false), 1000);
  };

  return (
    <div className={classes.root}>
      {/*heading*/}
      <Paper square elevation={0} className={classes.header}>
        <Typography variant="h4">{steps[activeStep]}</Typography>
      </Paper>

      {/*content*/}
      <Box>
        {getStepContent(activeStep)}
      </Box>

      {/*bottom*/}
      <MobileStepper
        className={classes.nav}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          paymentInProgress
            ? <CircularProgress size={30} />
            : <Button size="small" onClick={handleNext}>
              {isLastStep ? 'Finish' : 'Next'}
              <KeyboardArrowRight />
            </Button>

        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </div>
  );
}
