import { Avatar, Box, Button, Grid, Link, List, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useNotify from '../../hooks/useNotify';
import UserAvatar from '../util/UserAvatar';
import { connect } from 'react-redux';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formSingleValidator, formValidator, validationRules } from '../../util/formValidation';
import { apiRequest } from '../../util/util';
import { API_USER_FROFILE_UPDATE, API_USER_POINTS } from '../../util/constants';
import { loginRequestSuccess, updateUserPoints } from '../../store/user/userActions';
import StarsIcon from '@material-ui/icons/Stars';
import '../../assets/css/custom.scss';
import routes from '../../util/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';
import useSetting from '../../hooks/useSetting';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(3),
      width: '25ch',
      align: "center",
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};


const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    phone: false,
  }
};


function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) ? payload : 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function UserProfileComponent({ ...otherProps }) {

  const [userUpdateState, setUserUpdateState] = useState({
    phone: otherProps.user.phone
  });

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const classes = useStyles();

  const history = useHistory();

  const setting = useSetting([
    'points_enabled'
  ]);

  const [notify] = useNotify();

  const [loading, setLoading] = useState(false);

  const [pointLoader, setPointLoader] = useState(false);

  const validationSchema = {
    phone: [
      validationRules.required(),
      validationRules.number(),
    ],
  };

  const formHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserUpdateState({
      ...userUpdateState,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);


      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();

  }

  const checkoutPoint = () => {
    history.push(routes.points);
  }


  useEffect(() => {

    setPointLoader(true);

    const amountObject = {
      total_amount: 10000,
    }

    async function getPoints() {

      try {
        const pointApi = await apiRequest.post(API_USER_POINTS, amountObject);
        if (pointApi.data.status) {
          
          otherProps.updateUserPoints(parseFloat(pointApi.data.data.total_points));

          setPointLoader(false);

        } else {
          setPointLoader(false);
        }
      } catch (e) {
        notify.error("Something Wrong, Please try again later");
        setPointLoader(false);
      }

    }

    getPoints();
  }, []);



  const submitUpdateHandler = async () => {

    setLoading(true);

    const { error, data } = await formValidator(userUpdateState, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }

    const userUpdateResponse = await apiRequest.post(API_USER_FROFILE_UPDATE, userUpdateState)

    if (userUpdateResponse.status) {
      // console.log(userUpdateResponse.data.data);
      otherProps.loginRequestSuccess(userUpdateResponse.data.data);
      setLoading(false);

      notify.success("Your Information updated");


    } else {
      setLoading(false);
      notify.error("Something went wrong, Please try again later");
    }


  };

  return (<>

    <SlideAnimation in={true}>
      
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={8} md={5} component={Paper} square>
        <Box align="center" padding={2}>

          <Avatar className={classes.large}>
            <UserAvatar />
          </Avatar>

          <form noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              disabled
              id="name"
              name="name"
              value={otherProps.user.name}
              autoFocus

            />
            <TextField
              type="email"
              variant="outlined"
              margin="normal"
              fullWidth
              disabled
              id="email"
              name="email"
              value={otherProps.user.email}
            />


            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="phone"
              label="Phone no."
              name="phone"
              error={!!errorState.fields.phone}
              helperText={errorState.fields.phone}
              value={userUpdateState.phone}
              onChange={formHandler}

            />

            {
              loading &&
              <CircularProgress />

            }

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              onClick={submitUpdateHandler}
              style={{ marginTop: 10, padding: 8 }}
            >
              Update information
            </Button>

          </form>
        </Box>
      </Grid>

      {
        setting.points_enabled &&
      <Grid item xs={12} sm={8} md={5} component={Paper} height="300" square>
        <Box align="center">
          {
            pointLoader &&
            <CircularProgress size={65} className="point-text" />
          }
          {
              otherProps.totalPoint === 0 && !pointLoader &&
            <Typography
              className="point-text"
            >
              You have no points. <br /> Start ordering to earn points
            </Typography>
          }

          {
              otherProps.totalPoint !== 0 && !pointLoader &&

            <Box>

              <Typography
                className="point-text"
              >
                You have <br />
                <span className="point-value"> <StarsIcon fontSize="large" /> {otherProps.totalPoint}  points </span>
                <br />
                <Button
                  type="button"
                  className="point-link"
                  variant="contained"
                  color="primary"
                  onClick={() => checkoutPoint()}

                >
                  checkout How it works
                </Button>

              </Typography>

            </Box>

          }




        </Box>
      </Grid>
      }



    </Grid>



    </SlideAnimation>
  </>);
}

const mapStateToProps = state => ({
  user: state.user.data,
  totalPoint: state.user.data.point?.points,
  defaultPoint: state.user.data,
});

const mapDispatchToProps = dispatch => ({
  loginRequestSuccess: (user) => dispatch(loginRequestSuccess(user)),
  updateUserPoints: (point) => dispatch(updateUserPoints(point)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent);
