import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring'; // cjs for IE 11

const SlideAnimation = forwardRef(function Fade(
  { in: open, delay=0, speed=30, children, onEnter, onExited, ...otherProps },
  ref
) {

  const style = useSpring({
    from: {
      transform: "translate3d(100%,0,0)"
    },
    to: {
      transform:  open ? "translate3d(0%,0,0)" : "",
      transitionDuration: `${speed}ms`
    },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    delay:delay,
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...otherProps}>
      {children}
    </animated.div>
  );
});

SlideAnimation.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  delay: PropTypes.number,
  speed: PropTypes.number,
  onExited: PropTypes.func,
};

export default SlideAnimation;
