import { validator as validatorPlugin } from 'indicative';
import validationMessages from './validationMessages';
import _ from 'lodash';

export async function formValidator(dataObj, validationSchema, messages={}) {
  const { validate } = validatorPlugin;
  
  // merge error messages
  const errorMessages = {
    ...validationMessages,
    ...messages
  };
  
  try {
  
    await validate(dataObj, validationSchema, errorMessages);
  
    // return subject data if there's no error
    return {
      error: false,
      data: dataObj
    };
    
  } catch (e) {
    const validationData = {
      error: true,
      data: e
    };
    
    return {
      error: true,
      data: mapValidationErrors(validationData)
    };
  }
}

export async function formSingleValidator(dataObj, validationSchema, messageObj='') {
  const { validate } = validatorPlugin;
  
  // get the field is subject to validate
  const property = Object.keys(dataObj)[0];
  
  // get validation rules definition array
  const validationRules = validationSchema[property];
  
  // if there's not validation defined for the field
  if (_.isEmpty(validationRules)) {
    const data = {
      error: false,
      data: dataObj
    };
    return mapValidationErrors(data);
  }
  
  // merge error messages
  const errorMessages = _.isEmpty(messageObj)
    ? validationMessages
    : createErrorMessageForField(property, messageObj)
  ;
  
  // prepare validation rules
  const rules = {
    [property]: validationRules
  };
  
  try {
    
    // return data when validated
    const data = await validate(dataObj, rules, errorMessages);
    
    // as validation passed, we'll check all fields
    
    const validationData = {
      error: false,
      data
    };

    return mapValidationErrors(validationData);
    
  } catch (e) {
    
    // return with validation errors
    const validationData = {
      error: true,
      data: e
    };
    
    return mapValidationErrors(validationData);
  }
}

function mapValidationErrors({error, data}) {
  
  // return error
  if (error) {
    const field = data[0];
    return {[field.field]: field.message};
  }
  
  // return fields with no errors
  const field = Object.keys(data)[0];
  return {[field]: false};
}

function createErrorMessageForField(fieldName, msgObj) {
  
  const messages = {};
  
  for (let msg in msgObj) {
    messages[`${fieldName}.${msg}`] = msgObj[msg];
  }
  
  return messages;
}
