import { validator } from 'indicative';


// custom validation rules

validator.extend('matchWithField', {
  async: false, // mark as async or not
  compile(args) {

    if (!Array.isArray(args)) {
      throw new Error(`matchWithField: arguments must be an array`);
    }
  
    if (args.length !== 2) {
      throw new Error(`matchWithField: 1st index should be the field name and 2nd index should be the value of that field`);
    }
    
    return args;
  }, // check / transform args
  validate(data, field, args, config) {

    const fieldValue = data.original[field];
    
    return (fieldValue === args[1]);
  } // do validation
});


validator.extend('containsNumber', {
  async: false, // mark as async or not
  compile(args) {
  
    // if (!parseInt(args)) {
    //   throw new Error(`containsNumber: Field should contain Number`);
    // }
    
    return args;
  }, // check / transform args
  validate(data, field, args, config) {

    const fieldValue = data.original[field];
    var numberElement;
    try{
      numberElement = fieldValue.match(/\d+/)[0];

    }catch(e){

    }
    if(numberElement>=1){
      return true;
    }else{
      return false
    }
   
  } // do validation
});


validator.extend('containOnlyNumber', {
  async: false, // mark as async or not
  compile(args) {
  
    // if (!parseInt(args)) {
    //   throw new Error(`containsNumber: Field should contain Number`);
    // }
    
    return args;
  }, // check / transform args
  validate(data, field, args, config) {

    const fieldValue = data.original[field];
    var numberElement;
    try{
      numberElement = fieldValue.match(/^[0-9]+$/) != null;;
    
    }catch(e){

    }
    if(numberElement){
      return true;
    }else{
      return false
    }
   
  } // do validation
});




// export all rules
export const validationRules = {
  ...validator.validations
};
