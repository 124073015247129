import { Button, TextField } from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/pageHeader/pageHeader';
import useSetting from '../../hooks/useSetting';
import _ from 'lodash';
import { formSingleValidator, formValidator, validationRules } from '../../util/formValidation';
import useNotify from '../../hooks/useNotify';
import PageLoader from '../../components/util/PageLoader';
import { apiRequest } from '../../util/util';
import { API_CONTACT_SUBMIT, SETTING_FILE_PATH } from '../../util/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Styles from '../../assets/css/custom.scss';
import ThemeImage from '../../components/util/ThemeImage';
import { connect } from 'react-redux';
import Image from 'material-ui-image';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';


const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const defaultContactFields = {
  name: '',
  email: '',
  subject: '',
  message: '',
}

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    name: false,
    email: false,
    subject: false,
    message: false,
  }
};


function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) ? payload : 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}


function ContactPage({ ...otherProps }) {

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  const [contactBtnText, setContactBtnText] = useState("Send Message");

  const [btnEnable, setBtnEnable] = useState(true);

  const contactSetting = useSetting([
    'email',
    'phone',
    'address',
    'country_code',
    'contact_map',
    'about_img',
  ]);

  const [contactFormState, setContactFormState] = useState({
    defaultContactFields
  });

  const [notify] = useNotify();

  const [showMap, setShowMap] = useState(0);

  useEffect(function () {
    setShowMap(1);
  }, []);

  const [loading, setLoading] = useState(false);

  const validationSchema = {
    name: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([6]),
      validationRules.max([30])
    ],
    email: [
      validationRules.required(),
      validationRules.min([10]),
      validationRules.email(),
    ],
    subject: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([15]),
      validationRules.max([40])

    ],
    message: [
      validationRules.required(),
      validationRules.string(),
      validationRules.min([20]),
      validationRules.max([250])

    ],
  };

  const formHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactFormState({
      ...contactFormState,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);


      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();

  }

  useEffect(() => {

    if (otherProps.user.data.name === "") return;

    setContactFormState({
      ...contactFormState,
      name: otherProps.user.data.name,
      email: otherProps.user.data.email,
    });


  }, [otherProps.user]);

  const contactSubmitHandler = async (e) => {

    e.preventDefault();

    const { error, data } = await formValidator(contactFormState, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }

    setLoading(true);
    setBtnEnable(false);
    setContactBtnText("Processing...");

    const contactObject = {

      name: contactFormState.name,
      email: contactFormState.email,
      subject: contactFormState.subject,
      message: contactFormState.message,

    };

    const contactResponse = await apiRequest.post(API_CONTACT_SUBMIT, contactObject);

    if (contactResponse.data.status) {

      setLoading(false);
      setBtnEnable(true);
      setContactBtnText("Send Message");
      notify.info("Your message successfully sent");

      setContactFormState(defaultContactFields);

    } else {
      notify.error(contactResponse.data.messages);
    }

  };


  return (<>


    <SlideAnimation in={true}>
      <PageHeader pageName="Contact Us" bgClass="breadcrumb-bg-contact" />


      <section className="w3l-contact-2">
        <div className={`contact-infubd section-gap pt-5  pb-5 ${Styles.backgroundDark}`}>
          <div className="container">
            <div className="row">

              <div className="col-md-6 col-sm-12 col-lg-6">

                <Image animationDuration={3000} disableError={true} src={`${SETTING_FILE_PATH}${contactSetting.about_img}`} className="img-fluid" alt="Contact-image" />

              </div>

              <div className="col-md-6 col-sm-12 col-lg-6">
                <h2 className="text-center exo2">Leave us a Message</h2>


                <form noValidate onSubmit={contactSubmitHandler}>

                  <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="name"
                    label="Full Name"
                    autoComplete='off'
                    id="name"
                    error={!!errorState.fields.name}
                    helperText={errorState.fields.name}
                    value={contactFormState.name}
                    onChange={formHandler}
                  />


                  <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    label="Email Address"
                    autoComplete='off'
                    id="email"
                    error={!!errorState.fields.email}
                    helperText={errorState.fields.email}
                    value={contactFormState.email}
                    onChange={formHandler}
                  />


                  <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="subject"
                    label="Subject"
                    autoComplete='off'
                    id="subject"
                    error={!!errorState.fields.subject}
                    helperText={errorState.fields.subject}
                    value={contactFormState.subject}
                    onChange={formHandler}
                  />

                  <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="message"
                    label="Your Message"
                    autoComplete='off'
                    id="message"
                    error={!!errorState.fields.message}
                    helperText={errorState.fields.message}
                    value={contactFormState.message}
                    onChange={formHandler}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    margin="normal"
                    style={{ padding: 12, marginTop: 5 }}
                  >
                    {
                      loading &&
                      <CircularProgress color="secondary" size={25} />

                    }

                    {contactBtnText}

                  </Button>

                </form>

              </div>
            </div>


          </div>
        </div></section>
      {/* /contact-form-2 */}
      <div className="map-iframe">
        <iframe src={contactSetting.contact_map} width="100%" height={400} frameBorder={0} style={{ border: 0, pointerEvents: 'none' }} allowFullScreen />
      </div>

    </SlideAnimation>

  </>);
}

const mapStateToProps = state => ({
  user: state.user,
});


export default connect(mapStateToProps)(ContactPage);
