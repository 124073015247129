import {
  GET_USER_REQUEST,
  GET_USER_REQUEST_FAIL,
  GET_USER_REQUEST_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAIL,
  LOGIN_REQUEST_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_REQUEST_FAIL,
  LOGOUT_REQUEST_SUCCESS,
  REGISTRATION_REQUEST, REGISTRATION_REQUEST_FAIL, REGISTRATION_REQUEST_SUCCESS,
  ADD_USER_ADDRESSES,
  DELETE_USER_ADDRESS,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_POINTS,
  UPDATE_ERROR,
  REQUIRE_AUTH
} from './userTypes';

import {
  AUTH_ENDPOINT_LOGIN,
  AUTH_ENDPOINT_LOGOUT, AUTH_ENDPOINT_REGISTER,
  AUTH_USER_ENDPOINT,
  PRE_AUTH_ENDPOINT,
  USER_ADDRESS
} from '../../util/constants';
import { apiRequest } from '../../util/util';

///// Login
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  };
};

export const loginRequestFail = err => {
  return {
    type: LOGIN_REQUEST_FAIL,
    payload: err
  };
};

export const addUserAddresses = (address) => {
  return {
    type: ADD_USER_ADDRESSES,
    payload: address
  };
};

export const deleteUserAddresses = (address) => {
  return {
    type: DELETE_USER_ADDRESS,
    payload: address
  };
};

export const updateUserAddresses = (address) => {
  return {
    type: UPDATE_USER_ADDRESS,
    payload: address
  };
};


export const loginRequestSuccess = user => {
  return {
    type: LOGIN_REQUEST_SUCCESS,
    payload: user
  };
};

export const updateErrors = (payload) => {
  return {
    type: UPDATE_ERROR,
    payload: payload,
  };
};

export const updateRequireAuth = (payload) => {
  return {
    type: REQUIRE_AUTH,
    payload: payload,
  }
}

export const login = (email, password, remember = false) => {
  return async (dispatch) => {

    dispatch(loginRequest());

    try {

      // allow auth (pre auth request)
      await apiRequest.get(PRE_AUTH_ENDPOINT);

      // authenticate
      await apiRequest.post(AUTH_ENDPOINT_LOGIN, { email, password, remember });

      // get auth user
      const user = await apiRequest.get(AUTH_USER_ENDPOINT);

      dispatch(loginRequestSuccess(user.data?.data));

    } catch (e) {

      dispatch(loginRequestFail(e.response?.data));
    }

  };
};



///// Register
export const registrationDirect = (name, email, password, password_confirmation, phone) => {
  return async () => {

    try {

      // allow auth (pre auth request)
      await apiRequest.get(PRE_AUTH_ENDPOINT);

      // authenticate
      const registration = await apiRequest.post(AUTH_ENDPOINT_REGISTER, { name, email, password, password_confirmation, phone });

      // get auth user
      // const user = await apiRequest.get(AUTH_USER_ENDPOINT);

      return registration;

    } catch (e) {
      return e.response?.data;
    }

  };
};

export const saveAddress = (addressData) => {
  return async (dispatch) => {
    try {

      const address = await apiRequest.post(USER_ADDRESS, addressData);

      dispatch(addUserAddresses(address.data));

      return address.data;

    } catch (e) {
      return e.address?.data;
    }
  }
}

export const removeAddress = (deleteUrl) => {
  return async (dispatch) => {
    try {

      const addressDelete = await apiRequest.delete(deleteUrl);

      dispatch(deleteUserAddresses(addressDelete.data));

      return addressDelete;

    } catch (e) {
      return e.addressDelete?.data;
    }
  }
}


export const updateAddress = (updatedData, updateUrl) => {
  return async (dispatch) => {
    try {

      const addressUpdate = await apiRequest.post(updateUrl, updatedData);

      dispatch(updateUserAddresses(addressUpdate.data));

      return addressUpdate.data;

    } catch (e) {
      return e.addressUpdate?.data;
    }
  }
}




///// LogOut
export const logOutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

export const logOutRequestFail = err => {
  return {
    type: LOGOUT_REQUEST_FAIL,
    payload: err
  };
};

export const logOutRequestSuccess = user => {
  return {
    type: LOGOUT_REQUEST_SUCCESS,
    payload: user
  };
};

export const logOut = () => {
  return async (dispatch) => {

    dispatch(logOutRequest());

    try {

      // get auth user
      const user = await apiRequest.post(AUTH_ENDPOINT_LOGOUT);

      dispatch(logOutRequestSuccess(user.data));

    } catch (e) {
      dispatch(logOutRequestFail(e.response.data));
    }

  };
};


///// Get auth user

export const getUserRequest = () => {
  return {
    type: GET_USER_REQUEST
  };
};

export const getUserRequestFail = err => {
  return {
    type: GET_USER_REQUEST_FAIL,
    payload: err
  };
};

export const getUserRequestSuccess = user => {
  return {
    type: GET_USER_REQUEST_SUCCESS,
    payload: user
  };
};

export const getUser = (alsoSetInStore = false) => {

  return async (dispatch) => {

    dispatch(getUserRequest());

    try {

      // get auth user
      const user = await apiRequest.get(AUTH_USER_ENDPOINT);

      if (alsoSetInStore) {
        dispatch(getUserRequestSuccess(user.data?.data));
      }

      return user;

    } catch (e) {
      dispatch(getUserRequestFail(e.response?.data));
    }

  };
};

export const updateUserPoints = (payload) => {
  return {
    type: UPDATE_USER_POINTS,
    payload: payload,
  }
}
