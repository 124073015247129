import { Badge, BottomNavigation, BottomNavigationAction, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, SwipeableDrawer } from '@material-ui/core';
import { ViewList, ShoppingCart, Publish, TrainOutlined } from '@material-ui/icons';
import { useEffect, useReducer, useState } from 'react';
import styles from './BottomNav.module.scss';
import clsx from 'clsx';
import Cart from '../../pages/order/cart/cart';
import { connect } from 'react-redux';
import SideMenu from '../menu/sideMenu';
import isMobile from 'react-device-detect';
import CartPreview from '../../components/cart/cartPreview';
import { CURRENCY_SYMBOL } from '../../util/constants';
import routes from '../../util/routes';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { updateShowLeftSideBar } from '../../store/layout/layoutActions';

const useStyles = makeStyles({
  listRight: {
    width: "80vw",
  },
  listLeft: {
    width: "80vw",
  },
  fullList: {
    width: 'auto',
  },
});

function BottomNav({ ...otherProps }) {

  const classes = useStyles();

  const location = useLocation();

  const history = useHistory();

  const [isMenuClick, setIsMenuClick] = useState(false);

  const [isCartClick, setIsCartClick] = useState(false);

  const [badgeDisable, setBadgeDisable] = useState(true);

  const orderPage = routes.order;

  const orderConfirmationPage = routes.orderConfirmation;

  const currentPath = location.pathname;

  const [totalOrderCost, setTotalOrderCost] = useState(0);

  const calculateTotalItems = (items) => {

    const itemsTotal = items.reduce((acc, item) => (acc + item.qty), 0);

    return itemsTotal ? itemsTotal : 'No';
  }


  useEffect(() => {

    let totalCost = otherProps.cart.itemsTotal + otherProps.cart.addonsTotal;
    totalCost -= otherProps.discount.value;
    totalCost -= otherProps.coupon.value;
    totalCost += otherProps.deliveryCharge.charge;

    setTotalOrderCost(totalCost);


  }, [otherProps.cart.items, otherProps.discount, otherProps.coupon, otherProps.deliveryCharge]);


  useEffect(() => {
    if (otherProps.itemsTotal <= 0) {
      setBadgeDisable(true);
      return;
    }
    setBadgeDisable(false);
  }, [otherProps.itemsTotal]);

  useEffect(() => {

    if (otherProps.isShowLeftSideBar) {
      toggleDrawer("left", true);
    }

  }, [otherProps.isShowLeftSideBar]);


  const [state, setState] = useState({
    left: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const menuBottomHandler = () => {
    if (currentPath === orderPage) {
      toggleDrawer("left", true);

    } else {
      history.push(routes.order);
    }
  };


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const scrollToMenu = () => {
    window.scrollTo(0, 150);
  };

  const listCategory = (anchor) => (
    <div
      className={clsx(classes.listLeft)}
      role="presentation"
      onClick={closeLeftSideBar}
    >

      <SideMenu />


    </div>
  );

 
  const closeLeftSideBar = () => {
    toggleDrawer("left", false);
    otherProps.updateShowLeftSideBar(false);
    scrollToMenu();
  }

  return (<>
    <div className={styles.BottomNavContainer} >

      <BottomNavigation className={styles.BottomNav}>

        <BottomNavigationAction
          className={styles.BottomItem}
          label="Menu"
          showLabel={true}
          icon={<ViewList />}
          onClick={() => menuBottomHandler()}
        />

        <Badge
          badgeContent={`${CURRENCY_SYMBOL}${totalOrderCost?.toFixed(2)}`}
          invisible={badgeDisable} color="primary"
        >
          <BottomNavigationAction
            className={styles.BottomItem}
            label="Cart"
            showLabel={true}
            icon={<ShoppingCart />}
            onClick={() =>  toggleDrawer("right", true)}
          />

        </Badge>


        {

          currentPath != orderPage &&
          <BottomNavigationAction
            className={styles.BottomItem}
            label="Jump"
            showLabel={true}
            icon={<Publish />}
            onClick={scrollToTop}
          />
        }

        {
          currentPath === orderPage &&
          <BottomNavigationAction
            className={styles.BottomItem}
            label="Items"
            showLabel={true}
            icon={calculateTotalItems(otherProps.cart.items)}
          />
        }



      </BottomNavigation>

      <SwipeableDrawer
        anchor={'left'}
        open={state['left']}
        onClose={closeLeftSideBar}
        onOpen={() => toggleDrawer('left', true)}
      >
        {listCategory('left')}

      </SwipeableDrawer>

      <SwipeableDrawer
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false)}
        onOpen={() => toggleDrawer('right', true)}
      >


        <div
          className={clsx(classes.listRight)}
          role="presentation"
        >
          {
            currentPath != orderConfirmationPage &&
            <Cart drawerControlHandler={toggleDrawer} />
          }

          {
            currentPath === orderConfirmationPage &&
            <CartPreview />
          }


        </div>

      </SwipeableDrawer>
    </div>
  </>);
}

const mapStateToProps = state => ({
  cart: state.cart,
  discount: state.cart.discount,
  coupon: state.cart.coupon,
  deliveryCharge: state.cart.delivery,
  itemsTotal: state.cart.itemsTotal,
  isShowLeftSideBar: state.layout.show_left_side_bar,
});

const mapDispatchToProps = dispatch => ({
  updateShowLeftSideBar: (isUpdate) => dispatch(updateShowLeftSideBar(isUpdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomNav);
