import PropTypes from 'prop-types';
import ModalComp from '../util/Modal/ModalComp';
import SplitForm from '../order/stripePayment';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Grid } from '@material-ui/core';


function stripeModal({ show, selectedPaymentMethod, resetPaymentMethodHandler, ...otherProps }) {



  return (<>
    <ModalComp
      title="Make Payment"
      open={show}
      hideFooter={true}
      closeHandler={resetPaymentMethodHandler}
      closeOnBackdropClick={false}
      disableFocusEnforcement={true}
    >
      <Grid >
        <SplitForm />

      </Grid>
    </ModalComp>
  </>);
}


export default stripeModal;
