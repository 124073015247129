import { makeStyles } from '@material-ui/core/styles';

export const offerStyles = makeStyles((theme) => ({
  highlightWord: {
    color: theme.palette.secondary.light,
    whiteSpace: 'nowrap'
  },
  offerContainer: {
    border: '2px solid',
    borderStyle: 'dashed',
    borderColor: theme.palette.grey['500'],
    padding: 10,
    borderRadius: 7
  },
  offerTitle: {
    margin: theme.spacing(1)
  }
}));
