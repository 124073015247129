import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';

//only for development
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));


//for production
// const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
