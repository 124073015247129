import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import store from './store';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Footer2 from './components/footer/footer2';
import Footer3 from './components/footer/footer3';
import HomePage from './pages/home';
import AboutPage from './pages/about';
import Error404Page from './pages/error/Error404Page';
import GalleryPage from './pages/gallery';
import ContactPage from './pages/contact';
// import ReservationPage from './pages/reservation';
import MenuPage from './pages/order/menu/menuPage';
import Login from './pages/auth/login';
import ForgetPassword from './pages/auth/forgetPassword';
import routes from './util/routes';
import ResetPassword from './pages/auth/resetPassword';
import Register from './pages/auth/register';
import OrderConfirmation from './pages/order/comfirmation/orderConfirmationPage';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './components/util/theme';
import OrderSuccess from './pages/order/success/orderSuccess';
import OrderHistoryPage from './pages/order/orderHistory';
import UserProfile from './pages/order/userProfile';
// import Awards from './pages/awards';
import Privacy from './pages/privacy';
import Points from './pages/points';
import Terms from './pages/conditions';
import AllergyInformation from './pages/allergy';
import { SnackbarProvider } from "notistack";
import { SERVER_BASE_URL } from './util/constants';



function App() {


  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider dense preventDuplicate>

          <CssBaseline />
          <div className="App">
            <Header />

            <Switch>
              <Route path={routes.home} exact component={HomePage} />
              <Route path={routes.order} exact component={MenuPage} />
              <Route path={routes.orderConfirmation} component={OrderConfirmation} />
              <Route path={routes.orderSuccess} component={OrderSuccess} />

              <Route path={routes.gallery} component={GalleryPage} />
              <Route path={routes.about} component={AboutPage} />
              {/* <Route path={routes.reservation} component={ReservationPage} /> */}
              <Route path={routes.contact} component={ContactPage} />

              <Route path={routes.signup} component={Register} />
              <Route path={routes.login} component={Login} />
              <Route path={routes.forgetPassword} component={ForgetPassword} />
              <Route path={routes.resetPassword} component={ResetPassword} />

              <Route path={routes.orderHistory} component={OrderHistoryPage} />
              <Route path={routes.userProfile} component={UserProfile} />
              {/* <Route path={routes.awards} component={Awards} /> */}

              <Route path={routes.privacyPolicy} component={Privacy} />
              <Route path={routes.points} component={Points} />

              <Route path={routes.manage} component={() => {
                window.location.href = SERVER_BASE_URL;
                return null;
              }} />


              <Route path={routes.termsConditions} component={Terms} />
              <Route path={routes.allergyInformation} component={AllergyInformation} />



              <Route component={Error404Page} />
            </Switch>

            <Footer3 />
          </div>

        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}



export default App;
