import { apiRequest } from '../../util/util';
import {
  SETTING_GET_OPENING_HOURS_REQUEST,
  SETTING_GET_OPENING_HOURS_FAIL,
  SETTING_GET_OPENING_HOURS_SUCCESS,
  SETTING_VALIDATE_OPENING_HOURS_STATE,
  RESTAURANT_CLOSED_STATE,
  UNAVAILABLE_ORDER_TYPE,
  UNAVAILABLE_ORDER_TYPE_WITH_SELECTED_TIME,
  CLEAR_UNAVAILABLE_ORDER_TYPE_WITH_SELECTED_TIME,

} from './openingTypes';
import { API_GET_OPENING } from '../../util/constants';


// --- opening hours --- //

const getOpeningHoursRequest = () => {
  return {
    type: SETTING_GET_OPENING_HOURS_REQUEST
  };
};

const getOpeningHoursFail = err => {
  return {
    type: SETTING_GET_OPENING_HOURS_FAIL,
    payload: err
  };
};

const getOpeningHoursSuccess = data => {
  return {
    type: SETTING_GET_OPENING_HOURS_SUCCESS,
    payload: data
  };
};

export const updateOpeningHoursOpenState = openState => {
  return {
    type: SETTING_VALIDATE_OPENING_HOURS_STATE,
    payload: openState
  };
}

export const updateUnavailableOrderType = payload => {
  return {
    type: UNAVAILABLE_ORDER_TYPE,
    payload: payload,
  };
}

export const updateIsRestaurantClosed = payload => {
  return {
    type: RESTAURANT_CLOSED_STATE,
    payload: payload
  };
}

export const updateUnavailableOrderWithTime = payload => {
  return {
    type: UNAVAILABLE_ORDER_TYPE_WITH_SELECTED_TIME,
    payload: payload
  }
}

export const clearUnavailableOrderWithTime = () => {
  return {
    type: CLEAR_UNAVAILABLE_ORDER_TYPE_WITH_SELECTED_TIME
  }
}

export const getSettingOpeningHour = () => {
  return async (dispatch) => {

    dispatch(getOpeningHoursRequest());

    try {

      // allow auth (pre auth request)
      const response = await apiRequest.get(API_GET_OPENING);

      dispatch(getOpeningHoursSuccess(response.data?.data));

    } catch (e) {
      dispatch(getOpeningHoursFail(e.response?.data));
    }

  };
};


