import React from 'react';
import styles from './allMenu.module.scss';

import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@material-ui/core';
import { CURRENCY_SYMBOL } from '../../util/constants';
import { ExpandMore } from '@material-ui/icons';
import { menuStyles } from './menu.style';
import AddToCart from '../cart/addToCart';

export default function MenuItem({item, groupItems}) {

  const classes = menuStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderItem = item => {
    return (
      <Grid container key={item.id} className={styles.MenuItem}>
        <Grid item xs={12} className={styles.ItemNameAndPriceContainer}>
          <Typography variant="h4" component="h2" className={styles.ItemName}>
            {item.name}
          </Typography>
          <Box className={styles.ItemPriceBox}>
            <Box className={classes.menuItemPrice}>{CURRENCY_SYMBOL} {item.price.toFixed(2)}</Box>
            <Box ml={1}>
              <AddToCart item={item} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" component="div" className={styles.ItemDescription}>
            {item.description}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderGroup = (item) => {
    return (<>
      <Accordion
        expanded={expanded === item.id}
        onChange={handleChange(item.id)}
        className={styles.MenuItemGroup}
      >
        <AccordionSummary
          expandIcon={<ExpandMore className={[styles.AddItemToCart, styles.MenuItemGroupIcon]} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={classes.menuGroupHeading}
        >
          <Typography
            variant="h4"
            component="h2"
            className={classes.menuGroupHeadingText}
          >
            {item.name}
          </Typography>
          <Typography
            variant="subtitle1"
            className={[styles.MenuItemGroupItemCount, classes.menuGroupHeadingTextCount]}
            style={{marginLeft: 7}}
          >
            {groupItems.length} {(groupItems.length > 1) ? 'Items' : 'Item'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{flexDirection: 'column'}}>
          {
            groupItems.map(itm => renderItem(itm))
          }
        </AccordionDetails>
      </Accordion>
    </>);
  };

  if (!item.is_group && !item.group_id) {
    return renderItem(item);
  }

  if (item.is_group) {
    return renderGroup(item);
  }

  if (item.group_id) {
    return <></>;
  }

}
