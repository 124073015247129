import { makeStyles } from '@material-ui/core/styles';

export const mainStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const btnStyle = makeStyles((theme) => ({
  root: {
    width: '100%'
  }
}));

export const cartItemAddon = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f8e4e8'
  }
}));

