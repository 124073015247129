import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, colors, Grid, makeStyles, MobileStepper, Paper, Typography } from '@material-ui/core';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import './stripePayment.css';
import useNotify from "../../hooks/useNotify";
import { connect } from "react-redux";
import { apiRequest } from "../../util/util";
import { API_GET_ORDER_TOKEN, API_STORE_ORDER } from "../../util/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../util/routes";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: 18,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          },

        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    []
  );

  return options;
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  nav: {
    backgroundColor: colors.grey["200"],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const SplitForm = ({ ...otherProps }) => {

  const stripe = useStripe();

  const steps = ['Card Details'];

  const classes = useStyles();

  const elements = useElements();

  const [activeStep, setActiveStep] = useState(0);
  
  const maxSteps = steps.length;

  const [paymentInProgres, setPaymentInProgress] = useState(false);
  
  const options = useOptions();

  const history = useHistory();

  const [paymentState, setPaymentState] = useState({
    total: 0,
    processing: false,
    paid: false
  });

  const [notify] = useNotify();

  const [tokenState, setTokenState] = useState({
    loading: true,
    token: '',
    id: '',
    initialized: false
  });

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (<>
          {
            (tokenState.token ==='' ) &&
            <CircularProgress />
          }
          {
            (stripe && elements && tokenState.token !='' ) &&
            <Grid container>
              <Grid item xs={12} lg={12}>
                <form >
                  <div>
                    <label>
                      Card number
                      <CardNumberElement
                        options={options}

                      />
                    </label>
                  </div>

                  <div>
                    <label>
                      Expiration date
                      <CardExpiryElement
                        options={options}
                      />
                    </label>

                  </div>

                  <div>
                    <label>
                      CVC
                      <CardCvcElement
                        options={options}
                      />
                    </label>
                  </div>
                </form>

              </Grid>

            </Grid>
          }


        </>);

      default:
        throw new Error('Undefined step');
    }
  };

  const applyToken = async () => {

    try {

      const response = await getToken();

      setTokenState({
        ...tokenState,
        loading: false,
        initialized: false,
        ...response.data
      });

      return response.data;

    } catch (e) {
      notify.error('SERVER ERROR: Something went wrong. Try again later!');
      return false;
    }

  };

  const getToken = async () => {

    let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
    totalAmount += otherProps.orderDelivery.charge;
    totalAmount -= otherProps.orderDiscount.value;
    totalAmount -= otherProps.orderCoupon.value;

    setPaymentState({
      ...paymentState,
      total: totalAmount
    });

    let tokenRequestData = {
      amount: totalAmount.toFixed(2),
    }

    const orderToken = await apiRequest.post(API_GET_ORDER_TOKEN, tokenRequestData);
    return orderToken
  };

  // get token if payment type is card & don't have token
  useEffect(() => {
    // don't continue if token exists
    if (tokenState.token.length) return;

    applyToken();

  }, []);

  const saveOrder = async (trx_id) => {

    let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
    totalAmount += otherProps.orderDelivery.charge;
    totalAmount -= otherProps.orderDiscount.value;
    totalAmount -= otherProps.orderCoupon.value;
    totalAmount -= otherProps.cart.pointsToMoney;
    totalAmount += otherProps.cart.adjustPointMoney;

    const { cart } = otherProps;

    const orderRequestData = {
      items: cart.items.map(itm => ({
        id: itm.id,
        qty: itm.qty,
        addons: itm?.selected_addon.map(addon =>addon.id),
      })),
      // addons: cart.addons.map(itm => ({
      //   id: itm.id,
      //   item_id: itm.item_id
      // })),
      coupon: {
        ...cart.coupon,
        amount: cart.coupon.value,
      },
      discount: {
        ...cart.discount,
        amount: cart.discount.value.toFixed(2),
      },
      points: cart.orderPoint,
      delivery: {
        address_id: cart.delivery.address.id,
        charge: cart.delivery.charge,
        distance: cart.delivery.distance,
        date: cart.delivery.date,
        time: cart.delivery.time,
        is_asap: cart.delivery.isAsapTime
      },
      order: cart.order,
      payment: {
        ...cart.payment,
        amount: totalAmount.toFixed(2),
        trxId: trx_id,
        status: true,
      }
    };

    if (otherProps.orderType === "Collection") {
      orderRequestData.delivery = {
        address_id: "",
        date: cart.delivery.date,
        time: cart.delivery.time,
        is_asap: cart.delivery.isAsapTime,

      };
    }

    try {

      const orderResponse = await apiRequest.post(API_STORE_ORDER, orderRequestData);
      return orderResponse.data;

    } catch (e) {

      notify.error('Something went wrong, Please try again in a while.');

    }

  };

  const handleSubmit = async event => {

    event.preventDefault();

    setPaymentInProgress(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.confirmCardPayment(tokenState.token, {
      payment_method: {
        card: elements.getElement(CardNumberElement)
      }
    });


    if (payload.error) {
      setPaymentInProgress(false);
      notify.error(payload.error.message);
      return;
    }

    if (payload.paymentIntent) {

      const orderSave = await saveOrder(payload.paymentIntent.id);

      if (orderSave.status) {
        setPaymentInProgress(false);
        notify.success("Order Successfull");
        history.push(routes.orderSuccess);
      }else{
        setPaymentInProgress(false);
        notify.warning("Something wrong, please try later");
      }

    }

  };

  return (<>

    <div className={classes.root}>
      {/*heading*/}
      <Paper square elevation={0} className={classes.header}>
        <Typography variant="h4">{steps[activeStep]}</Typography>
      </Paper>

      {/*content*/}
      <Box>
        {getStepContent(activeStep)}
      </Box>

      {/*bottom*/}
      <MobileStepper
        className={classes.nav}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          paymentInProgres ?
          <CircularProgress/> : 
          <Button size="small" onClick={handleSubmit}>
            Finish

          </Button>
        }
      />
    </div>

  </>);
};

const mapStateToProps = state => ({
  user: state.user,
  cart: state.cart,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderDiscount: state.cart.discount,
  orderCoupon: state.cart.coupon,
  orderDelivery: state.cart.delivery,
  deliveryAddress: state.cart.delivery.address,
  orderType: state.cart.order.type,
});

export default connect(mapStateToProps)(SplitForm);
