import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: 15,
      minWidth: 250,
      maxWidth: 350,
    },
    header: {
        textTransform: 'capitalize',
        fontSize: 25,
    },
    selectedAddressBorder: {
        border: '1px solid',
        borderColor: theme.palette.primary.main
    },
    addressCardHeader: {
        backgroundColor: '#ececec',
        padding: 5
    },
    addressItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 5
    },
    switchLabel: {
        marginRight: -10
    },
    menuIconSize: {
        fontSize: 30
    },
    marginTop20:{
        marginTop:30,
    },
    spaceBetween:{
        display: "space-between",
    },
    selectedAddressBackgroundColor:{
        backgroundColor: '#f3f3f3',
    },
    addressHeader: {
        backgroundColor: '#f3f3f3'
    },

    cursorHover:{
        cursor: 'pointer',
    }

}));


