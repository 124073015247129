import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Divider, Grid } from '@material-ui/core';
import UserAddressAddEdit from '../user/userAddressAddEdit';
import Address from '../../components/address/address'
import { cartDeliveryAddressUpdate } from "../../store/cart/cartActions";


function AddressSelection({ ...otherProps }) {

  const [selectedAddress, setSelectedAddress] = useState(() => {
    // select 1st address if there's only one
    if (otherProps.addresses.length < 2) return otherProps.addresses[0] || null;

    // select default address if available
    const defaultAddress = otherProps.addresses.find(addr => addr.is_default);

    return (defaultAddress) ? defaultAddress : {};
  });


  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(()=>{
    if(otherProps.addresses.length===1){
      setSelectedAddress(otherProps.addresses[0]);
    }
  },[otherProps.addresses]);

  useEffect(() => {
    otherProps.setDeliveryAddress(selectedAddress);
  }, [selectedAddress]);

  return (<>




      <Grid container>
        <Grid item xs={12} md={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Address
            addresses={otherProps.addresses}
            selectedAddress={selectedAddress}
            selectAddressHandler={setSelectedAddress}
            showEditHandler={setShowEditModal}
            clearSelectedHandler={() => setSelectedAddress(null)}
          />
        </Grid>

      {
        otherProps.addresses.length !== 0 &&
        <Box my={2}>
          OR
      </Box>
      }


        <Grid item xs={12} md={12}>
          <UserAddressAddEdit
            address={selectedAddress}
            showEditPopUp={showEditModal}
            showEditPopUpHandler={setShowEditModal}
            clearSelectedAddressHandler={setSelectedAddress}
          />
          <Box mb={2} />
        </Grid>
      </Grid>



  </>);
}

const mapStateToProps = state => ({
  addresses: state.user.data.addresses,
  user: state.user.data,
  orderType: state.cart.order.type,
});

const mapDispatchToProps = dispatch => ({
  setDeliveryAddress: (address) => dispatch(cartDeliveryAddressUpdate(address))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressSelection);
