import { useState } from 'react';
import {connect} from "react-redux";
import styles from '../menu/allMenu.module.scss';
import { AddCircle } from '@material-ui/icons';
import CartItemAddonsPopup from './cartItemAddonsPopup';
import { useSpring, animated } from 'react-spring';
import useNotify from "../../hooks/useNotify";

function AddToCart({item, ...otherProps}) {

  const [notify] = useNotify();
  
  const [hoverState, setHoverState] = useState(false);
  
  const animationProps = useSpring({
    transform: hoverState
      ? `scale(1.1,1.1)`
      : `scale(1,1)`,
    // transition: `transform ${50}ms`,
  });
  const AnimeIcon = animated(AddCircle);
  
  const animationStart = () => {
    setHoverState(true);
  };
  
  const animationStop = () => {
    setHoverState(false);
  };

  const openAddonsPopupHandler = (cb) => {
    if (otherProps.isOffDay) {
      notify.info(`Today we're closed`);
      return;
    }

    cb();
  };
  
  return (<>
    <CartItemAddonsPopup
      item={item}
    >
      {
        (openAddonsPopup) =>(
          <AnimeIcon
            onMouseEnter={animationStart}
            onMouseLeave={animationStop}
            onClick={() => openAddonsPopupHandler(openAddonsPopup)}
            className={styles.AddItemToCart}
            style={animationProps}
          />
        )
      }
    </CartItemAddonsPopup>
    
  </>);
}

const mapStateToProps = state => ({
  isOffDay: state.opening.isOffDay
});

export default connect(mapStateToProps)(AddToCart);
