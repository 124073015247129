import {
  SEO_GET_REQUEST,
  SEO_GET_FAIL,
  SEO_GET_SUCCESS
} from './seoTypes';

const defaultState = {
  loading: false,
  data: {},
  error: '',
  errors: {}
};

const reducer = (state = defaultState, {type, payload}) => {

  switch (type) {

    case SEO_GET_REQUEST:
      return {
        ...state,
        loading: true
      }

    case SEO_GET_FAIL:
      return {
        ...state,
        loading: false,
        data: {},
        error: payload?.message,
        errors: payload?.errors || {}
      }

    case SEO_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: '',
        errors: {}
      }

    default: return state
  }

};

export default reducer;
