import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { menuStyles } from './menu.style';
import { ExpandMore } from '@material-ui/icons';
import { connect } from 'react-redux';
import { SelectedMenuAdded } from '../../store/menu/menuActions';
import '../../../src/assets/css/custom.scss';



function SideMenu({ selectHandler, ...otherProps }) {

  const [selected, setSelected] = useState('none');

  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCategorySelect = (category) => {
    otherProps.SelectedMenuAdded(category, otherProps.items[category.name]);

  };

  useEffect(() => {

    if (selected === 'none' && otherProps.selectedCategory) {
      // update category selection
      handleSelection(otherProps.selectedCategory);

      // open accordion if its a parent or child
      if (otherProps.selectedCategory.is_parent || otherProps.selectedCategory.parent_id) {
        const accordionId = (otherProps.selectedCategory.is_parent) ? otherProps.selectedCategory.id : otherProps.selectedCategory.parent_id;
        setExpanded(accordionId);
      }
    }
  }, [otherProps.selectedCategory]);



  const handleSelection = (category) => {
    setSelected(category.name);
    handleCategorySelect(category);
  }

  useEffect(()=>{
    setSelected(otherProps.selectedCategory.name);
  },[otherProps.selectedCategory]);

  const styles = menuStyles();

  const renderCategoryItem = category => {
    return (<>

      <ListItem
        key={`${category.id}c`}
        className={`${styles.categoryMenuItemElement} ${selected === category.name ?
          styles.selectedBackgroundColor : ""}`}
        selected={selected === category.name}
        onClick={() => handleSelection(category)}
      >
        <ListItemText 
        primary={category.name}
          className={` ${selected === category.name ?
            styles.selectedColor : ""}`} />
      </ListItem>
    </>);
  };

  const renderCategoryWithChilds = category => {

    const flatCategories = [category, ...category.childs];

    return (
      <Accordion
        key={category.id}
        expanded={expanded === category.id}
        onChange={handleAccordionChange(category.id)}
        style={{bottomBorder: '1px solid #141414'}}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          id={`header${category.id}`}
          className={styles.menuGroupHeading}
        >
          <Typography component="h2">
            {category.name}

          </Typography>

        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {
            flatCategories.map(cat => renderCategoryItem(cat))
          }
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderCategory = category => {

    if (category.is_parent) {
      return renderCategoryWithChilds(category);
    }

    return renderCategoryItem(category);
  };

  return (<>
    <Typography  component="h2" color="primary" style={{fontSize:25, padding: 8}}>
      Menu Categories
    </Typography>
    <List component="nav" >
      {
        otherProps.storeCategories.map(category => renderCategory(category))
      }

    </List>
  </>);
}

const mapStateToProps = state => ({
  storeCategories: state.menu.categories,
  items: state.menu.items,
  selectedCategory: state.menu.selectedCategory,
});

const mapDispatchToProps = dispatch => ({
  SelectedMenuAdded: (selectedCategory, selectedItems) => dispatch(SelectedMenuAdded(selectedCategory, selectedItems)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
