import { Card, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useNotify from '../../hooks/useNotify';
import useSetting from '../../hooks/useSetting';
import { useStyles } from './cardPayment.style';

function PaymentMethods({ cartSelection, cartSelectionHandler, ...otherProps }) {

  const classes = useStyles();

  const [notify] = useNotify();

  const paymentSetting = useSetting([
    'payment_cash',
    'payment_card',
  ]);

  const handleCard = (event) => {
    cartSelectionHandler(event.target.value);
  }

  const showCardText = () => {
    notify.info("You can proceed your order by selecting cash but pay by card over the phone by calling at Restaurant.");
  }

  return (<>
    <RadioGroup className={[classes.root, classes.cursorHover].join(' ')} value={cartSelection} onChange={handleCard}>
      {
        paymentSetting.payment_cash &&
        <Grid container >
          <Card className={[classes.root].join(' ')} style={{ width: "100vw" }}>
            <FormControlLabel value="cash" control={<Radio color="primary" />} label={`Cash`} />
          </Card>
        </Grid>
      }

      {
        paymentSetting.payment_card &&
        <Grid container style={{ marginTop: 20 }}>
          <Card className={[classes.root].join(' ')} style={{ width: "100vw" }}>
            <FormControlLabel value="card" control={<Radio color="primary" />} label={`Card`} />
          </Card>
        </Grid>

      }

      {
        !paymentSetting.payment_card &&
        <Grid container style={{ marginTop: 20 }}>
          <Card className={[classes.root].join(' ')} onClick={showCardText} style={{ width: "100vw" }}>
            Card

          </Card>
        </Grid>

      }

    </RadioGroup>
  </>);
}

PaymentMethods.propTypes = {
  cartSelection: PropTypes.string,
  cartSelectionHandler: PropTypes.func,
};

const mapStateToProps = state => ({
  orderType: state.cart.order,
});


export default connect(mapStateToProps)(PaymentMethods);
