import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => {
  
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: props => props.color || '#fff',
      backgroundColor: props => props.bgColor || ''
    },
  };
});

export default function PageLoader({show=false, loaderColor, backgroundColor}) {
  
  const classes = useStyles({
    color: loaderColor,
    bgColor: backgroundColor,
  });
  
  return (
    <Backdrop className={classes.backdrop} open={show}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
