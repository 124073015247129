import ModalComp from "../util/Modal/ModalComp";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { offerStyles } from "./Offers.style";
import { apiRequest, formatDateTime } from "../../util/util";
import { API_GET_OFFERS, CURRENCY_SYMBOL } from "../../util/constants";
import useNotify from "../../hooks/useNotify";
import _ from 'lodash';
import { connect } from "react-redux";
import { updateShowAutoOfferPopup } from "../../store/layout/layoutActions";

function Offers({ ...otherProps }) {
  const classes = offerStyles();
  const [notify] = useNotify();

  const [offers, setOffers] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {

    if ((
      offers.discount?.length ||
      offers.coupon?.length
    )
      && !otherProps.show_auto_offer_popup
    ) {
      otherProps.updateShowAutoOfferPopup(true);
      setOpen(true);
    }
  }, [offers]);

  const closeHandler = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  useEffect(async () => {

    // dont load if offer already available
    if (!_.isEmpty(offers)) return;

    try {

      const response = await apiRequest.get(API_GET_OFFERS);

      setOffers({...response.data.data});

    } catch (e) {
      notify.error(`Sorry! temporarily offers are unavailable`);
    }

  }, []);

  const getOrderTypeForPrint = type => {
    if (type === 'both') {
      return 'Collection & Delivery'
    }

    return type.replace(type[0], type[0].toUpperCase());
  };

  const printOffers = () => {
    return (<>
      <Grid item xs={12} lg={6}>
        <Typography variant="h4" className={classes.offerTitle}>Discounts</Typography>
        {
          !offers.discount?.length &&
          <Box mx={1}>
            <Typography variant="h5">Sorry! No discount available at this time.</Typography>
          </Box>
        }
        {
          offers.discount?.map(discount => (
            <Box mx={1} marginBottom={2} key={discount.id} className={classes.offerContainer}>
              <Typography variant="h5" color="primary">
                {discount.fixed && CURRENCY_SYMBOL}
                {discount.amount}
                {!discount.fixed && ' %'} discount
              </Typography>
              <Typography>
                on {getOrderTypeForPrint(discount.for)} orders
              </Typography>
              <Typography variant="caption">
                Valid till <span className={classes.highlightWord}>
                {formatDateTime(discount.to)}
              </span>
              </Typography>
            </Box>
          ))
        }
      </Grid>

      <Grid item xs={12} lg={6}>
        <Typography variant="h4" className={classes.offerTitle}>Coupons</Typography>
        {
          !offers.coupon?.length &&
          <Box mx={1}>
            <Typography variant="h5">Sorry! No coupon available at this time.</Typography>
          </Box>
        }
        {
          offers.coupon?.map(coupon => (
            <Box mx={1} marginBottom={2} key={coupon.id} className={classes.offerContainer}>
              <Typography variant="h5" color="primary">
                {coupon.code}
              </Typography>
              <Typography>
                <span className={classes.highlightWord}>
                  {coupon.fixed && CURRENCY_SYMBOL}
                  {`${coupon.amount} `}
                  {!coupon.fixed && '% '}
                </span>
                off on {getOrderTypeForPrint(coupon.for)} orders
              </Typography>
              <Typography variant="caption">
                Valid till <span className={classes.highlightWord}>
                {formatDateTime(coupon.to)}
              </span>
              </Typography>
            </Box>
          ))
        }

      </Grid>
    </>);
  };

  return (<>
    {
      otherProps.children(openModal)
    }

    <ModalComp
      title="Available Offers"
      open={open}
      closeHandler={closeHandler}
      hideFooter
    >
      <Grid container>

        {
          _.isEmpty(offers) &&
          <Grid item xs={12}>
            <Typography variant="h4">Sorry! No offers available right now</Typography>
          </Grid>
        }

        {
          !_.isEmpty(offers) && printOffers()
        }

      </Grid>
    </ModalComp>
  </>);
}

const mapStateToProps = state => ({
  show_auto_offer_popup: state.layout.show_auto_offer_popup,
});

const mapDispatchToProps = dispatch => ({
  updateShowAutoOfferPopup: (type) => dispatch(updateShowAutoOfferPopup(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
