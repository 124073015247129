import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
    padding: 20,
  },
  cursorHover: {
    cursor: 'pointer',
  },
  loadingCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  marginTop20: {
    marginTop: 20,
  },
  display: {
    display: 'flex',
    justifyContent: 'space-between',
  },

}));


