import React, { useEffect, useState } from 'react';
import MainSlider from '../../components/slider/main/MainSlider';
import PromotionalSlider from '../../components/slider/promotionalSlider/PromotionalSlider';
import axios from 'axios';
import { connect } from 'react-redux';
import useSetting from '../../hooks/useSetting';
import { Box } from '@material-ui/core';
import { SETTING_FILE_PATH } from '../../util/constants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import About from '../about/about';
import ReservationComponent from '../../components/reservation/reservation';
import routes from '../../util/routes';
import _ from 'lodash';
import PageLoader from '../../components/util/PageLoader';
import { red } from '@material-ui/core/colors';
import ReviewSlider from '../../components/slider/ReviewSlider';
import Gallery from '../gallery/index';
import '../../assets/css/custom.scss';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';

axios.defaults.withCredentials = true;


const HomePage = ({ ...otherProps }) => {



  const homeSetting = useSetting([
    'allergy_information',
    'site_title',
    'food_img_1',
    'food_img_2',
    'food_img_3',
    'food_img_4',
    'food_img_title_1',
    'food_img_title_2',
    'food_img_title_3',
    'food_img_title_4',
    'review_img_1',
    'review_img_2',
  ]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    otherProps.settings.loading ? setIsLoading(true) : setIsLoading(false);

  }, [otherProps.settings.loading]);


  return (<>
    <PageLoader show={isLoading} backgroundColor="#4C181E" />

    <SlideAnimation in={true}>

      <MainSlider />




      {/* home page service grids */}
      <section id="services" className="home-services pt-5 pb-5">


        <div className="container pt-3">

          <About showBtn={true} />

        </div>
      </section>

      <PromotionalSlider />



      {/* Reservation Component */}

      <Gallery hideBredCrumb={true} />

      {/*  Allergy section */}
      <section className="w3l-stats parallax-allergy-section">
        <div className="main-w3 py-5" id="stats">


          <div className="container text-center">
            <h2 className="text-white fugaz-font ">Allergy Information</h2>
            <Box marginBottom={3} marginTop={3} className="cover-content">
              <h5 className="text-white text-left">{homeSetting.allergy_information}</h5>
            </Box>

            <Link to={routes.order} className="read-more btn btn-style btn-primary" >
              View Our Menu
            </Link>


          </div>

        </div>
      </section>

    </SlideAnimation>
  </>);
}

const mapStateToProps = state => ({
  user: state.user.data,
  lastUser: state.user.lastUser,
  items: state.menu.items,
  settings: state.setting,
});

export default connect(mapStateToProps)(HomePage);
