import React, { useEffect } from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import ThemeImage from '../../components/util/ThemeImage';
import About from './about';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';

function AboutPage() {

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);


  return (<>

    <SlideAnimation in={true}>
      <PageHeader pageName="About Us" />

      {/* features-4 */}
      <section className="w3l-services-6-main home-services">
        <div className="services-6 py-5">
          <div className="container py-md-3">

            <About showBtn={false} />

          </div>
        </div>
      </section>
    
      {/* //content-with-photo-16 */}
      {/* team */}
      {/* <section className="content-with-photo-16 py-5" id="w3l-team">
        <div className="container py-lg-4 py-sm-3">
          <h3 className=" mb-md-5 mb-4 text-center">Interior View</h3>
          <div className="row text-center">
            <div className="col-md-3 col-6">
              <div className="team-grids text-center">
                <ThemeImage src="images/decoration1.jpg" className={"img-fluid decoration decoration-image"} />

              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="team-grids text-center">
                <ThemeImage src="images/decoration2.jpg" className="img-fluid" alt />

              </div>
            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-sm-5 mt-5">
              <div className="team-grids text-center">
                <ThemeImage src="images/decoration3.jpg" className="img-fluid" />

              </div>

            </div>
            <div className="col-md-3 col-6 mt-md-0 mt-sm-5 mt-5">
              <div className="team-grids text-center">
                <ThemeImage src="images/decoration4.jpg" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </SlideAnimation>
  </>);
}

export default AboutPage;
